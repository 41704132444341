// Animation
;(function($, window, document){

	// 確認網頁上有動畫Class
	document.body.classList.add('has-ani');

	var countToPluginLoad = (typeof $.fn.countTo === 'function');

	var scrollMagicBuilding = function(){

		var wh = window.innerHeight;

		var controller = new ScrollMagic.Controller();

		// Data Animation
		var dataAnimation = document.querySelectorAll('[data-animation]');
		[].forEach.call(dataAnimation, function(el){
			el.aniTimer = null;
			new ScrollMagic.Scene({
				triggerElement: el
			})
			.setClassToggle(el, "is-passing")
			.on('enter leave', function(e){
				var currentTitle = el.querySelector('.sec-title-box') || el.querySelector('.title');

				if(currentTitle === null) return false;

				currentTitle.style.willChange = "opacity";

				if(el.aniTimer != null){
					clearTimeout(el.aniTimer);
				}
				el.aniTimer = setTimeout(() => {
					currentTitle.style.willChange = "";
					el.aniTimer = null;
				}, 600);
			})
			.addTo(controller);
		});

		// Deco Text
		var decotext = document.querySelectorAll('.deco-text');
		if(window.innerWidth > 1200){
			[].forEach.call(decotext, function(el){
				var wrapH = el.parentElement.clientHeight;
				// var elY = Math.round(wrapH * 0.3 / el.clientHeight * 100) + "%";
				var elY = "100%";
				el.aniTimer = null;

				new ScrollMagic.Scene({
					triggerElement: el,
					duration: wrapH * 0.8,
				})
				.setTween(el, {
					y: elY, 
					ease: Linear.easeNone,
					autoRound: false,
				})
				.on('shift', function(){
					el.style.willChange = 'transform';
					
					if(el.aniTimer != null){
						clearTimeout(el.aniTimer)
					}
					el.aniTimer = setTimeout(function(){
						el.style.willChange = '';
						el.aniTimer = null;
					}, 100);
				})
				.addTo(controller)
			});
		}



		// -----------------------------
		// Zero-to-Market Hover Ani
		// Fixed IE Hover Svg Draw Line Bug
		if(document.documentElement.browser === 'IE'){
			var zeroBlock = $('#index-zero-slider'),
				targetA = '.swiper-slide:not([data-status="is-active"]) a';
			
			zeroBlock.on('mouseenter mouseleave', targetA, function(e){
				var _this = this,
					borderL = _this.querySelector('.outer-border-l'),
					borderR = _this.querySelector('.outer-border-r');

				var isHover = e.type === 'mouseenter';
				
				var borderStart = 600,
					borderLEnd = 1200,
					borderREnd = 0;

				var borderL_from = isHover ? borderStart : borderLEnd,
					borderL_to = isHover ? borderLEnd : borderStart,
					borderR_from = isHover ? borderStart : borderREnd,
					borderR_to = isHover ? borderREnd : borderStart;

				TweenMax.fromTo(borderL, 0.6, {ease: Power2.easeOut, strokeDashoffset: borderL_from}, {strokeDashoffset: borderL_to});
				TweenMax.fromTo(borderR, 0.6, {ease: Power2.easeOut, strokeDashoffset: borderR_from}, {strokeDashoffset: borderR_to});
			});
		}

		// -----------------------------
		// Intro
		var indexIntro = "#index-intro",
			$indexIntro = $(indexIntro);
		new ScrollMagic.Scene({
			triggerElement: indexIntro,
			duration: $indexIntro.innerHeight()
		})
		.setTween($indexIntro.children('.bg-box'), {
			y: "10%",
			ease: Linear.easeNone,
		})
		.addTo(controller);

		// -----------------------------
		// Data
		// EFFICIENCY
		var effiList = $("#data-effi-list");
		var effi__timer = null;
		new ScrollMagic.Scene({
			triggerElement: '#index-data'
		})
		.on("enter", function (event) {
			if(effi__timer != null){
				clearTimeout(effi__timer);
				effi__timer = null;
			}
			effi__timer = setTimeout(() =>{
				TweenMax.to(effiList.children('li').eq(0).find('path.percent'), 1, {strokeDasharray: "200, 600", ease:Linear.easeNone});
				TweenMax.to(effiList.children('li').eq(1).find('path.percent'), 1*2, {strokeDasharray: "400, 600", ease:Linear.easeNone});
				TweenMax.to(effiList.children('li').eq(2).find('path.percent'), 1/4*7, {strokeDasharray: "350, 600", ease:Linear.easeNone});
				TweenMax.to(effiList.children('li').eq(3).find('path.percent'), 1*1.5, {strokeDasharray: "300, 600", ease:Linear.easeNone});
				
				// Counter
				if(countToPluginLoad){
					effiList.find('.ani-num').countTo('restart');
				}
			},1200);

		})
		.on("leave add", function (event) {
			if(effi__timer != null){
				clearTimeout(effi__timer);
				effi__timer = null;
			}
			TweenMax.to(effiList.children('li').eq(0).find('path.percent'), 0, {strokeDasharray: "0, 600", ease:Linear.easeNone});
			TweenMax.to(effiList.children('li').eq(1).find('path.percent'), 0, {strokeDasharray: "0, 600", ease:Linear.easeNone});
			TweenMax.to(effiList.children('li').eq(2).find('path.percent'), 0, {strokeDasharray: "0, 600", ease:Linear.easeNone});
			TweenMax.to(effiList.children('li').eq(3).find('path.percent'), 0, {strokeDasharray: "0, 600", ease:Linear.easeNone});
		})
		.addTo(controller);

		// ---------
		// Rate (svg Draw Line)
		var dataRate = '#data-rate',
			$dataRate = $(dataRate),
			dataRate__timer1 = null,
			dataRate__timer2 = null;
		new ScrollMagic.Scene({
			triggerElement: dataRate
		})
		.on("enter", function (event) {
			if(dataRate__timer1 != null){
				clearTimeout(dataRate__timer1);
				dataRate__timer1 = null;
			}
			if(dataRate__timer2 != null){
				clearTimeout(dataRate__timer2);
				dataRate__timer2 = null;
			}
			dataRate__timer1 = setTimeout(() =>{
				TweenMax.to($dataRate.children('.left-box').find('path.line'), 1, {strokeDashoffset: 0, ease:Linear.easeNone});
				
				// Counter
				if(countToPluginLoad){
					$dataRate.children('.left-box').find('.ani-num').countTo('restart');
				}
			},500);
			dataRate__timer2 = setTimeout(() =>{
				TweenMax.to($dataRate.children('.right-box').find('path.line'), 1, {strokeDashoffset: 120, ease:Linear.easeNone});
				
				// Counter
				if(countToPluginLoad){
					$dataRate.children('.right-box').find('.ani-num').countTo('restart');
				}
			},2000);
		})
		.on("leave add", function (event) {
			if(dataRate__timer1 != null){
				clearTimeout(dataRate__timer1);
				dataRate__timer1 = null;
			}
			if(dataRate__timer2 != null){
				clearTimeout(dataRate__timer2);
				dataRate__timer2 = null;
			}
			TweenMax.to($dataRate.find('.line'), 1, {strokeDashoffset: 60, ease:Linear.easeNone});
		})
		.addTo(controller);
		// ---
		// Rate bg
		new ScrollMagic.Scene({
			triggerElement: dataRate,
			duration: $dataRate.innerHeight()
		})
		.setTween($dataRate.find('.bg-box'), {
			y: "10%",
			ease: Linear.easeNone,
		})
		.addTo(controller);

		// -----------------------------
		// Different
		// 目的：確認 .is-active 物件，進入再給Class
		var differId = "#index-differ";
		var differ__isActiveClass = "is-active";
		var activeCurrent;
		var differ__timer = null;
		new ScrollMagic.Scene({
			triggerElement: differId
		})
		.on("enter", function (event) {
			if(differ__timer != null){
				clearTimeout(differ__timer);
				differ__timer = null;
			}
			differ__timer = setTimeout(() =>{
				activeCurrent.addClass(differ__isActiveClass);
			}, 800)
		})
		.on("leave add", function (event) {
			if(differ__timer != null){
				clearTimeout(differ__timer);
				differ__timer = null;
			}
			if($(differId).find('.' + differ__isActiveClass).length > 0){
				activeCurrent = $(differId).find('.' + differ__isActiveClass);
			}
			activeCurrent.removeClass(differ__isActiveClass);
		})
		.addTo(controller);
	}
	// ------------------------------------ End scrollMagicBuilding Function

	// Start Animation
	registerListener('load', function(){
		setTimeout(() => {
			scrollMagicBuilding()
		}, 500);
	});
	
	var buildCounter = function(){
		countToPluginLoad = (typeof $.fn.countTo === 'function');
		$('.ani-num').each(function(index, el){
			var $el = $(el);
			var finishDe = $el.attr('data-finish-decimals');
			var countSpeed = function(){
				if(finishDe != undefined){
					return parseFloat($el.attr('data-to')) / 4 * 1000
				}else{
					return 1000
				}
			}
			
			$el.countTo({
				speed: countSpeed(),
				buildStart: false,
				onComplete: function (value) {
					if(finishDe != undefined){
						this[0].innerHTML = parseFloat(this[0].innerHTML).toFixed(finishDe)
					}
				}
			});
		});
	}
	registerListener('load', buildCounter);
})(jQuery, window, document);